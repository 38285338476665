import React from "react"

import SvgIcon from "../components/SvgIcon"

const IconKey = () => {
  return (
    <SvgIcon viewBox="0 0 40 40">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#00665E" fillRule="nonzero">
          <path d="M28.67,0 C35.446,0 40,4.756 40,11.834 L40,28.168 C40,35.246 35.446,40 28.668,40 L11.332,40 C4.554,40 0,35.246 0,28.168 L0,11.834 C0,4.756 4.554,0 11.332,0 L28.67,0 Z M13.682,14.798 C10.814,14.798 8.48,17.132 8.48,20 C8.48,22.87 10.814,25.204 13.682,25.204 C16.026,25.204 17.99,23.636 18.638,21.5 L22.862,21.5 L22.862,23.704 C22.862,24.532 23.534,25.204 24.362,25.204 C25.19,25.204 25.862,24.532 25.862,23.704 L25.862,21.5 L28.522,21.5 L28.522,23.704 C28.522,24.532 29.194,25.204 30.022,25.204 C30.85,25.204 31.522,24.532 31.522,23.704 L31.522,20 C31.522,19.172 30.85,18.5 30.022,18.5 L18.638,18.5 C17.99,16.366 16.026,14.798 13.682,14.798 Z M13.6822,17.7978 C14.8962,17.7978 15.8862,18.7858 15.8862,20.0018 C15.8862,21.2158 14.8962,22.2038 13.6822,22.2038 C12.4682,22.2038 11.4782,21.2158 11.4782,20.0018 C11.4782,18.7858 12.4682,17.7978 13.6822,17.7978 Z"></path>
        </g>
      </g>
    </SvgIcon>
  )
}

export default IconKey

export default {
  //ABOUT_TITLE: "<span>Instant</br>Bank Account<br/>for your Dog</span>",
  ABOUT_TITLE: "<span>Financial<br/>Freedom<br/>for all Dogs</span>",
  LEAD_BUTTON_TEXT_1: "让我们一起 Wow",
  LEAD_BUTTON_TEXT_2: "开始",
  ABOUT_TEXT_LEAD: " ",
  ABOUT_TEXT:
    "<h3>Get instant access to the financial system for your dog</h3><p>Fewer than 0.001% of the world's dogs have bank accounts - that's nearly <b style=\"color:#FFDD03\">1 billion unbanked dogs</b>. This restricts dogs' access to financial services such as <a href=\"https://www.youtube.com/watch?v=orjTdQf0Rn4\" style=\"color:#C3F1FF; text-decoration:underline\">401(k)9</a> retirement accounts and \"borrowing\" money for treats.</p><p><h3>How it Works</h3><p>Like banks, proofof.dog uses a fuss-free, state of the art <b style=\"color:#FFDD03\">#KYD</b> (<a href=\"https://en.wikipedia.org/wiki/Know_your_customer\" style=\"color:#C3F1FF; text-decoration:underline\">know your doge</a>) process to safely create a digital identity for your dog. Simply tweet a photo of your dog and his or her <b style=\"color:#FFDD03\">proofof.dog QR code</b> to <a href=\"https://twitter.com/proofofdog\" style=\"color:#C3F1FF; text-decoration:underline\">@proofofdog</a>.</p>",
//Of the 471 million pet dogs in the world, fewer than 0.001% have their own bank accounts. That’s not even counting the other 421 million free-range dogs that are not only unbanked, but also remain outside the domestication system. There are nearly <span style=\"font-weight:bold; color:#FFDD03\">one billion unbanked dogs</span>.</p><p><u>Proof-of-Dog brings social justice to all dogs</u>.</p><p>When you <a style=\"font-weight:bold; color:#FFDD03\" href=\"https://en.wikipedia.org/wiki/Know_your_customer\">#KYD</a> (know your doge), you create a Dogecoin address for your pup, and link it to your Twitter profile.</p><p>As a responsible dog owner, you want your beloved dog to stay healthy and happy by giving them proper documentation and the best financial tools available.</p><p>Dogecoin is money for dogs, and <span style=\"font-weight:bold;color:#FFDD02\">#proofOfDog</span> is the future.</p>",
  GUIDE_TITLE: "一份狗狗币礼物送给你的狗狗",
  GUIDE_STEP_1_SUBTITLE: "第一步",
  GUIDE_STEP_1_TITLE: "生成二维码钱包",
  GUIDE_STEP_1_SECTION_TITLE: "创建一个唯一的狗狗币地址",
  GUIDE_STEP_1_CARD_PK: "请保存您的公钥。狗狗与专属地址的照片，进行实名认证(KYD)",
  GUIDE_STEP_1_CARD_SK: "请保存并妥善保管您的私钥！",

  GUIDE_STEP_2_SUBTITLE: "第二步",
  GUIDE_STEP_2_TITLE: "拍照 #proofOfDog ",
  GUIDE_STEP_2_SECTION_TITLE: "拍照示范 🐶",
  GUIDE_STEP_2_CARD_KYD: "<TODO EXAMPLE KYD PHOTO>",


  GUIDE_STEP_3_SUBTITLE: "第三步",
  GUIDE_STEP_3_TITLE: "关注 @proofOfDog",
  GUIDE_STEP_3_SECTION_TITLE: "请关注官方推特 @proofOfDog",
  GUIDE_STEP_3_CARD_FOLLOW: "点击关注 @proofOfDog",

  GUIDE_STEP_4_SUBTITLE: "第四步",
  GUIDE_STEP_4_TITLE: "发推你的 #proofOfDog",
  GUIDE_STEP_4_SECTION_TITLE: "发推你的 #proofOfDog",
  GUIDE_STEP_4_CARD_VERIFY: "发推你的 #proofOfDog",

  GUIDE_CTA_TEXT: "新手指南",
  GETTING_STARTED_TITLE: "如何开始",
  MEME_DIP_ALT: "DIP-1337",
  MEME_LIONKING_ALT: "马一龙 Dogeba ",
  MEME_MAVS_ALT: "Dallas Dogericks ",
  MEME_SOON_ALT: "特斯拉 Doge",
  MEME_CYBERTRUCK_ALT: "Dogber Truck",
  MEME_COINBASE_ALT: "马一龙 Doge Coinbase ",
  MEME_FINISH_ALT: "Dogemacian Bobsled Team",
  MEME_RACECAR_ALT: "Dogecar",
  MEME_MANIFESTO_ALT: "DIP-1337",
  MEME_SAKURA_ALT: "Dogethereum",
  MEME_MOON_ALT: "Dogernaut",
  MEME_NEEDYOU_ALT: "Dogeaganda",
  MEME_JOAN_ALT: "JoanOfDoge",
  MEME_DOGEUS_ALT: "Dogeus",
  MEME_DOGETO_ALT: "Dogeto",
  REGISTRATION_TITLE: "狗狗新用户注册领空投，另有NFT空投惊喜",
  REGISTRATION_CTA_TEXT: "CTA REG",
  WALLET_OFFICIAL_TITLE: "私钥",
  WALLET_OFFICIAL_SUBTITLE: "请务必妥善保管！",
  WALLET_ALT_TITLE: "公钥",
  WALLET_ALT_SUBTITLE: "狗狗实名认证(KYD)公钥地址合照自拍",
  GET_STARTED_TITLE: "如何开始",
  GET_STARTED_SUBTITLE: "你的狗狗轻松拥有狗狗币和NFT，如此简单",
  FOOTER_FOLLOW_US: "关注我们",
  FOOTER_OR: "or",
  INPUT_USERNAME: "你的i Twitter @用户名",
  INPUT_DOGNAME: "狗狗名字",
  DOG_TAG: "DogTag",
  DOWNLOAD: "下载",
  PRESS_TO_TWEET: "发推",
  PUBLIC_KEY: "公钥",
  REMEMBER_ATTACH_PIC: "推文中请附上狗狗的照片以及钱包二维码图片",
  RESET: "重置",
  SAVE: "保存",
  SAVE_KEEP_SECRET: "保存并妥善保管",
  SECRET_KEY: "私钥",
  SUBMIT: "提交",
  TAKE_PICTURE: "狗狗与二维码合照来一张",
  TWEET_LINK: "认识你的狗链接",
  TWEET_MESSAGE: "推文",
  PROOF_OF_DOGE: "Proof of Doge",
  BANNER_WOW: "much wow",
  BANNER_RANDOM: "such random",
  TYPEWRITER_MUCH: "Much",
  TYPEWRITER_SUCH: "Such",
  TYPEWRITER_VERY: "Very",
  TYPEWRITER_SO: "So",
  TYPEWRITER_DOGE: "Doge",
  TYPEWRITER_CRYPTO: "Crypto",
  TYPEWRITER_COIN: "Coin",
  TYPEWRITER_HODL: "HODL",

}

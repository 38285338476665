import React from "react"

import SvgIcon from "../components/SvgIcon"

const IconDownload = () => {
  return (
    <SvgIcon viewBox="0 0 40 38">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#00665E" fillRule="nonzero">
          <path d="M18.4602,9.58104 L18.4602,1.56301 C18.4602,0.710459 19.1402,0 20.0002,0 C20.7702,0 21.4226,0.596983 21.5259,1.35316 L21.5402,1.56301 L21.5402,9.58104 L31.1,9.58165 C35.86,9.58165 39.7707,13.4796 39.9903,18.3408 L40,18.7722 L40,28.8508 C40,33.7461 36.2253,37.7644 31.5361,37.99 L31.12,38 L8.88,38 C4.12,38 0.22818,34.1216 0.00967557,29.2425 L0,28.8095 L0,18.7515 C0,13.8562 3.75582,9.81843 8.44399,9.59169 L8.86,9.58165 L18.46,9.58165 L18.46,22.3864 L15.26,19.082 C14.66,18.4624 13.68,18.4624 13.08,19.082 C12.78,19.3918 12.64,19.8048 12.64,20.2179 C12.64,20.5318 12.7296,20.8589 12.919,21.1359 L13.08,21.3331 L18.9,27.3638 C19.18,27.6736 19.58,27.8388 20,27.8388 C20.3333,27.8388 20.6667,27.724 20.9306,27.5065 L21.08,27.3638 L26.9,21.3331 C27.5,20.7135 27.5,19.7016 26.9,19.082 C26.3545,18.5187 25.495,18.4675 24.8925,18.9284 L24.72,19.082 L21.54,22.3864 L21.54,9.58165 L18.4602,9.58104 Z"></path>
        </g>
      </g>
    </SvgIcon>
  )
}

export default IconDownload

import React from "react"

import SvgIcon from "../components/SvgIcon"

const IconTwitter = () => {
  return (
    <SvgIcon viewBox="0 0 64 64">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#00665E" fillRule="nonzero">
          <path
        d="M51.5898546,21.5412593 C50.1969579,22.162795 48.7189413,22.5711083 47.205199,22.7525534 C48.7937436,21.7950537 49.9820025,20.2919907 50.5494279,18.5223417 C49.050964,19.4166215 47.4108826,20.0469075 45.7002961,20.3858711 C43.3372497,17.8625044 39.5804158,17.2428098 36.5361617,18.8742352 C33.4919077,20.5056606 31.917209,23.9825405 32.6949617,27.3554711 C26.5622897,27.0475126 20.8486326,24.1413903 16.9770862,19.3609299 C14.9541707,22.8562401 15.9870607,27.3266123 19.3366254,29.5730711 C18.1256609,29.5371091 16.9408203,29.2112499 15.8809223,28.6226711 L15.8809223,28.7158476 C15.9049843,32.3376429 18.4550666,35.4474329 21.9934295,36.1699652 C20.8725217,36.4750695 19.6969945,36.5196836 18.5563054,36.3004123 C19.5549491,39.4126762 22.41312,41.5459523 25.6720812,41.6114711 C22.9707745,43.7415679 19.6328964,44.8969503 16.1967662,44.8912829 C15.5757916,44.8913425 14.9553737,44.8540048 14.3388613,44.7794711 C21.0157507,49.1034801 29.5161482,49.4167906 36.4914328,45.5959782 C43.4667175,41.7751658 47.7988141,34.4326721 47.7811495,26.460977 L47.7811495,25.4733064 C49.2729778,24.3993458 50.5626054,23.0679546 51.5898546,21.5412593 L51.5898546,21.5412593 Z">
          </path>
        </g>
      </g>
    </SvgIcon>
  )
}

export default IconTwitter

export default {
  //ABOUT_TITLE: "<span>Instant</br>Bank Account<br/>for your Dog</span>",
  ABOUT_TITLE: "<span>Financial<br/>Freedom<br/>for all Dogs</span>",
  LEAD_BUTTON_TEXT_1: "Let’s Wow",
  LEAD_BUTTON_TEXT_2: "Start Now",
  ABOUT_TEXT_LEAD: " ",
  ABOUT_TEXT:
    "<h3>Get instant access to the financial system for your dog</h3><p>Fewer than 0.001% of the world's dogs have bank accounts - that's nearly <b style=\"color:#FFDD03\">1 billion unbanked dogs</b>. This restricts dogs' access to financial services such as <a href=\"https://www.youtube.com/watch?v=orjTdQf0Rn4\" style=\"color:#C3F1FF; text-decoration:underline\">401(k)9</a> retirement accounts and \"borrowing\" money for treats.</p><p><h3>How it Works</h3><p>Like banks, proofof.dog uses a fuss-free, state of the art <b style=\"color:#FFDD03\">#KYD</b> (<a href=\"https://en.wikipedia.org/wiki/Know_your_customer\" style=\"color:#C3F1FF; text-decoration:underline\">know your doge</a>) process to safely create a digital identity for your dog. Simply tweet a photo of your dog and his or her <b style=\"color:#FFDD03\">proofof.dog QR code</b> to <a href=\"https://twitter.com/proofofdog\" style=\"color:#C3F1FF; text-decoration:underline\">@proofofdog</a>.</p>",
//Of the 471 million pet dogs in the world, fewer than 0.001% have their own bank accounts. That’s not even counting the other 421 million free-range dogs that are not only unbanked, but also remain outside the domestication system. There are nearly <span style=\"font-weight:bold; color:#FFDD03\">one billion unbanked dogs</span>.</p><p><u>Proof-of-Dog brings social justice to all dogs</u>.</p><p>When you <a style=\"font-weight:bold; color:#FFDD03\" href=\"https://en.wikipedia.org/wiki/Know_your_customer\">#KYD</a> (know your doge), you create a Dogecoin address for your pup, and link it to your Twitter profile.</p><p>As a responsible dog owner, you want your beloved dog to stay healthy and happy by giving them proper documentation and the best financial tools available.</p><p>Dogecoin is money for dogs, and <span style=\"font-weight:bold;color:#FFDD02\">#proofOfDog</span> is the future.</p>",
  GUIDE_TITLE: "Give your doge the gift of Dogecoin",
  GUIDE_STEP_1_SUBTITLE: "STEP 1",
  GUIDE_STEP_1_TITLE: "Make a QR code wallet",
  GUIDE_STEP_1_SECTION_TITLE: "Make a unique Dogecoin address",
  GUIDE_STEP_1_CARD_PK: "SAVE your Public Key. Take doge pic with this one to KYD",
  GUIDE_STEP_1_CARD_SK: "SAVE your Private Key. Keep it secret!",

  GUIDE_STEP_2_SUBTITLE: "STEP 2",
  GUIDE_STEP_2_TITLE: "Take a #proofOfDog pic",
  GUIDE_STEP_2_SECTION_TITLE: "Take a pic like this 🐶",
  GUIDE_STEP_2_CARD_KYD: "<TODO EXAMPLE KYD PHOTO>",


  GUIDE_STEP_3_SUBTITLE: "STEP 3",
  GUIDE_STEP_3_TITLE: "Follow @proofOfDog",
  GUIDE_STEP_3_SECTION_TITLE: "Follow @proofOfDog on Twitter",
  GUIDE_STEP_3_CARD_FOLLOW: "Follow @proofOfDog on Twitter",

  GUIDE_STEP_4_SUBTITLE: "STEP 4",
  GUIDE_STEP_4_TITLE: "Tweet your #proofOfDog",
  GUIDE_STEP_4_SECTION_TITLE: "Tweet your #proofOfDog",
  GUIDE_STEP_4_CARD_VERIFY: "Tweet your #proofOfDog",

  GUIDE_CTA_TEXT: "Getting Started Guide",
  GETTING_STARTED_TITLE: "Getting Started",
  MAKE_PHOTO: "Make a photo",
  MEME_DIP_ALT: "DIP-1337",
  MEME_LIONKING_ALT: "Elon Dogeba ",
  MEME_MAVS_ALT: "Dallas Dogericks ",
  MEME_SOON_ALT: "Tesla Doge",
  MEME_CYBERTRUCK_ALT: "Dogber Truck",
  MEME_COINBASE_ALT: "Elon Doge Coinbase ",
  MEME_FINISH_ALT: "Dogemacian Bobsled Team",
  MEME_RACECAR_ALT: "Dogecar",
  MEME_MANIFESTO_ALT: "DIP-1337",
  MEME_SAKURA_ALT: "Dogethereum",
  MEME_MOON_ALT: "Dogernaut",
  MEME_NEEDYOU_ALT: "Dogeaganda",
  MEME_JOAN_ALT: "JoanOfDoge",
  MEME_DOGEUS_ALT: "Dogeus",
  MEME_DOGETO_ALT: "Dogeto",
  REGISTRATION_TITLE: "Register your Doge for Token and NFT drops",
  REGISTRATION_CTA_TEXT: "CTA REG",
  WALLET_OFFICIAL_TITLE: "Private Key",
  WALLET_OFFICIAL_SUBTITLE: "Keep it secret! Keep it safe!",
  WALLET_ALT_TITLE: "Public Key",
  WALLET_ALT_SUBTITLE: "Take your selfie with this one to KYD",
  GET_STARTED_TITLE: "Getting Started",
  GET_STARTED_SUBTITLE: "It is very easy to give your doge Dogecoin and NFTs",
  FOOTER_FOLLOW_US: "Follow us",
  FOOTER_OR: "or",
  INPUT_USERNAME: "your Twitter @username",
  INPUT_DOGNAME: "your doge's name",
  DOG_TAG: "DogTag",
  DOWNLOAD: "Download",
  PRESS_TO_TWEET: "Press to tweet",
  PUBLIC_KEY: "Public key",
  REMEMBER_ATTACH_PIC: "Remember to attach a pic of your doge and the public QR code to your tweet",
  RESET: "Reset",
  SAVE: "Save",
  SAVE_KEEP_SECRET: "Save this and keep it secret",
  SECRET_KEY: "Secret key",
  SUBMIT: "Submit",
  TAKE_PICTURE: "Take a picture of this QR with your dog",
  TWEET_LINK: "Know-your-dog Tweet",
  TWEET_MESSAGE: "Tweet message",
  PROOF_OF_DOGE: "Proof of Doge",
  BANNER_WOW: "much wow",
  BANNER_RANDOM: "such random",
  TYPEWRITER_MUCH: "Much",
  TYPEWRITER_SUCH: "Such",
  TYPEWRITER_VERY: "Very",
  TYPEWRITER_SO: "So",
  TYPEWRITER_DOGE: "Doge",
  TYPEWRITER_CRYPTO: "Crypto",
  TYPEWRITER_COIN: "Coin",
  TYPEWRITER_HODL: "HODL",
  SEND_DOGECOIN: "Send dogecoin to:",
  USE_CAMERA: "Use Camera"
}

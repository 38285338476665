export default {
  ABOUT_TITLE: "<span>Будущее<br/>финансов<br/>собак<br/>(питомцев)</span>",
  LEAD_BUTTON_TEXT_1: "Давай вау",
  LEAD_BUTTON_TEXT_2: "Начать сейчас",
  ABOUT_TEXT_LEAD: "1 млрд Доге хотят ходлить, самоедам нужно бабло.",
  ABOUT_TEXT:
    "<p>Из 471 миллион домашних собак в мире менее 0,001% имеют собственные банковские счета. Это даже не считая других 421 миллиона собак, находящихся на свободе, которые не обслуживаются банками. Существует почти <span style=\"font-weight:bold; color:#FFDD03\">один млрд собак, не охваченных банковскими услугами</span>.</p><p><u>Proof-of-Dog приносит социальную справедливость всем собакам</u>.</p><p>При использовании <a style=\"font-weight:bold; color:#FFDD03\" href=\"https://en.wikipedia.org/wiki/Know_your_customer\">#KYD</a> (know your doge - знай свой Доге), вы создаете Dogecoin адрес для своего питомца, затем связываете его с Твиттер-аккаунтом.</p><p>Как ответственный хозяин вы желаете, чтобы ваша любимая собака оставалась здоровой и счастливой, предоставляя ей надлежащую документацию и лучшие доступные финансовые инструменты.</p><p>Dogecoin - это деньги для собак, и <span style=\"font-weight:bold;color:#FFDD02\">#proofOfDog</span> - это будущее.</p>",
  GUIDE_TITLE: "Подарите своему Доге Dogecoin",
  GUIDE_STEP_1_SUBTITLE: "1-ый шаг",
  GUIDE_STEP_1_TITLE: "Создайте QR-код кошелек",
  GUIDE_STEP_1_SECTION_TITLE: "Создайте уникальный Dogecoin адрес",
  GUIDE_STEP_1_CARD_PK: "СОХРАНИТЕ ваш Публичный Ключ. Затем сфотографируйте вашего Доге с этим Ключом для KYD",
  GUIDE_STEP_1_CARD_SK: "СОХРАНИТЕ ваш Личный Ключ. Держите его в секрете!",

  GUIDE_STEP_2_SUBTITLE: "2-ой шаг",
  GUIDE_STEP_2_TITLE: "Сделайте фото с #proofOfDog",
  GUIDE_STEP_2_SECTION_TITLE: "Сделайте такое фото 🐶",
  GUIDE_STEP_2_CARD_KYD: "<TODO EXAMPLE KYD PHOTO>",

  GUIDE_STEP_3_SUBTITLE: "3-ий шаг",
  GUIDE_STEP_3_TITLE: "Подписывайтесь на @proofOfDog",
  GUIDE_STEP_3_SECTION_TITLE: "Подписывайтесь на @proofOfDog on Twitter",
  GUIDE_STEP_3_CARD_FOLLOW: "Подписывайтесь на @proofOfDog on Twitter",

  GUIDE_STEP_4_SUBTITLE: "4-ый шаг",
  GUIDE_STEP_4_TITLE: "Твитните ваш #proofOfDog",
  GUIDE_STEP_4_SECTION_TITLE: "Твитните ваш #proofOfDog",
  GUIDE_STEP_4_CARD_VERIFY: "Твитните ваш #proofOfDog",

  GUIDE_CTA_TEXT: "Начальная инструкция",
  GETTING_STARTED_TITLE: "Для начала",
  MEME_DIP_ALT: "DIP-1337",
  MEME_LIONKING_ALT: "Илон Догеба ",
  MEME_MAVS_ALT: "Даллас Догерикс ",
  MEME_SOON_ALT: "Тесла Доге",
  MEME_CYBERTRUCK_ALT: "Догбер Грузовик",
  MEME_COINBASE_ALT: "Илон Доге Коинбэйс ",
  MEME_FINISH_ALT: "Догемасиан бобслейная команда",
  MEME_RACECAR_ALT: "Догемашина",
  MEME_MANIFESTO_ALT: "DIP-1337",
  MEME_SAKURA_ALT: "Догефириум",
  MEME_MOON_ALT: "Догернаут",
  MEME_NEEDYOU_ALT: "Догеаганда",
  MEME_JOAN_ALT: "JoanOfDoge",
  MEME_DOGEUS_ALT: "Догеус",
  MEME_DOGETO_ALT: "Догетo",
  GET_STARTED_TITLE: "Для начала",
  GET_STARTED_SUBTITLE: "Очень просто приобрести вашему Доге Dogecoin и NFT",
  FOOTER_FOLLOW_US: "Подписывайтесь",
  FOOTER_OR: "или",
  BANNER_WOW: "much wow",
  BANNER_RANDOM: "such random",
  TYPEWRITER_MUCH: "много",
  TYPEWRITER_SUCH: "Such",
  TYPEWRITER_VERY: "Very",
  TYPEWRITER_SO: "So",
  TYPEWRITER_DOGE: "Doge",
  TYPEWRITER_CRYPTO: "Crypto",
  TYPEWRITER_COIN: "Coin",
  TYPEWRITER_HODL: "HODL",
}
